const collapseDropDown = (navbar, ddContent) => {
	navbar.style.maxHeight = '60px'
	setTimeout(() => {
		ddContent.classList.add('hidden')
	}, 300)
}

const navbarDropDown = () => {
	const navbarTrigger = document.querySelector('.in-nav-trigger')
	if(navbarTrigger){
		const ddContent = document.querySelector('.in-nav-dropdown')
		const navbar = document.querySelector('.secondary-navbar')
		navbarTrigger.addEventListener('click', () => {
			if(ddContent.classList.contains('hidden')){
				navbar.style.maxHeight = '300px'
				navbar.style.boxShadow = `0px 5px 6px -4px rgba(0,0,0,0.5)`
				ddContent.classList.remove('hidden')
			} else {
				collapseDropDown(navbar, ddContent)
			}
		})
		const anchors = ddContent.querySelectorAll('a')
		anchors.forEach((anchor) => {
			anchor.addEventListener('click', () => {
				collapseDropDown(navbar, ddContent)
			})
		})
	}
}

export { navbarDropDown }