import "bootstrap";
import "../utils/flatpickr"
import "../utils/tagify"
import { stickySecNav } from '../functions/stickySecondaryNavbar'
import { hgHoveredSolution } from '../functions/highlightHoveredSolution'
import { jsTabs } from '../functions/jsTabs'
import { accordion } from '../functions/accordion'
import { initMapbox } from '../functions/mapbox'
import { initResellerFilter } from '../functions/resellerFilter.js'
import { initPartnerFilter } from '../functions/partnerFilter.js'
import { initJobDropDown } from '../functions/toggleJobDropDown'
import { navbarDropDown } from '../functions/navbarDropDown'
import { stopBeforeFooter } from '../functions/stopBeforeFooter'
import { toggleFaq } from '../functions/toggleFaq'
import { urlInputValidation } from '../functions/urlInputValidation'
import {localeCheckboxes} from "../functions/localeCheckboxes"
import { wizard } from "../functions/wizard"
import { formViewer } from "../functions/formViewer"
import { subitoSignup } from "../functions/subitoSignup"
import * as ActiveStorage from "activestorage";
import "../utils/direct_uploads.js"

import Sortable from 'sortablejs';

ActiveStorage.start();

stickySecNav();
hgHoveredSolution();
jsTabs();
accordion();
initMapbox();
initResellerFilter();
initPartnerFilter();
initJobDropDown();
navbarDropDown();
stopBeforeFooter();
toggleFaq();
urlInputValidation();
localeCheckboxes();
wizard();
formViewer();
subitoSignup();

document.addEventListener('turbolinks:load', function() {
  gtag('config', 'UA-148910766-1')
});

$(document).ajaxSend(function(e, xhr, options) {
 var token = $("meta[name='csrf-token']").attr("content");
  xhr.setRequestHeader("X-CSRF-Token", token);
});

const sortables = document.querySelectorAll(".sortable")
sortables.forEach(function(sortable) {
  Sortable.create(sortable, {
    animation: 150,
    easing: "cubic-bezier(0.77, 0, 0.175, 1)",
    draggable: '.sort-item',
    onUpdate(e) {
      const order = [];
      $(e.target).find('.sort-item').each( (key, item) => {
        order.push($(item).data('id'));
      });
      $.post($(e.target).data('target'), {order: order});
    }
  })
})
