const hgHoveredSolution = () => {
  const parentDiv = document.querySelector('.highlightOnHover')
  if (parentDiv){
    const columns = parentDiv.querySelectorAll('.column-around')
    columns.forEach((column) => {
      column.addEventListener('mouseenter', (event) => {
        columns.forEach((col) => {
          if(col !== column){
            col.classList.add('halfopacity')
          }
        })
      })
      column.addEventListener('mouseleave', (event) => {
        columns.forEach((col) => {
            col.classList.remove('halfopacity')
        })
      })
    })
  }
}

export { hgHoveredSolution }
