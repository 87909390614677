const wizard = () => {
  const w = document.querySelectorAll(".wizard")
  
  if (w.length > 0) {
    
    // Listen to Previous / Next buttons
      // Hide current slide, show next slide
    // Disable prev for first slide
    // Disable next for last slide -> Show Submit.
    //
    
    const btnNext = document.querySelector(".btn-wz-next")
    const btnPrev = document.querySelector(".btn-wz-prev")
    
    const steps = document.querySelectorAll(".wizard-step")
    const breadCrumbs = document.querySelectorAll(".wz-bc")
    let currentIndex = 0
    
    const validateField = function(field) {
      if ((field.classList.contains('required') &&
            (field.value.replace(/\s+/,'') === "" || field.value.replace(/\s+/,'') === "false")) ||
          (field.type && field.type === 'checkbox' && field.classList.contains('required') && !field.checked)) {
        field.classList.add('is-invalid')
        return false
      } else {
        field.classList.remove('is-invalid')
        return true
      }
    }
    
    const onNextClicked = function (event) {
      
      let valid = true
      let fields = steps[currentIndex].querySelectorAll('input,select,textarea')
      
      fields.forEach((field) => {
        valid = validateField(field)
      })
      
      if (!valid) {
        return;
      }
      
      
      if (currentIndex + 1 < steps.length) {
        steps[currentIndex].classList.remove('active')
        
        breadCrumbs[currentIndex].classList.remove('active')
        breadCrumbs[currentIndex].classList.add('complete')
        
        currentIndex += 1;
        steps[currentIndex].classList.add('active')
        breadCrumbs[currentIndex].classList.add('active')
        
        if (btnPrev.disabled) {
          btnPrev.disabled = false;
        }
        
        if (currentIndex === steps.length - 1) {
          btnNext.innerHTML = btnNext.dataset.submit;
        }
        
        // Focus field
        const input = steps[currentIndex].querySelector('input,textarea,select');
        if (input) {
          input.focus();
        }
        
      } else {
        // Submit form
        w[0].querySelector('form').submit();
      }
    };
    
    btnNext.addEventListener('click', onNextClicked);
    
    // Press enter to move to next step
    w[0].addEventListener('keydown', (event) => { 
      // Skip textareas
      if (event.srcElement.localName == 'textarea') {
        return
      } else if (event.key === 'Enter') {
        event.preventDefault();
        onNextClicked();
      }
    });
    
    btnPrev.addEventListener('click', (event) => {
      if (currentIndex > 0) {
        steps[currentIndex].classList.remove('active')
        breadCrumbs[currentIndex].classList.remove('active')
        breadCrumbs[currentIndex].classList.remove('complete')
        
        currentIndex -= 1;
        steps[currentIndex].classList.add('active')
        breadCrumbs[currentIndex].classList.add('active')
        breadCrumbs[currentIndex].classList.remove('complete')
      }
      
      btnNext.innerHTML = btnNext.dataset.next;
      
      if (currentIndex === 0) {
        btnPrev.disabled = true;
      }
    });
  }  
}

export { wizard }
