function checkOffset(fixed, footer) {
  function getRectTop(el){
    var rect = el.getBoundingClientRect();
    return rect.top;
  }
  
  if((getRectTop(fixed) + document.body.scrollTop) + fixed.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10)
    fixed.style.position = 'absolute';
  if(document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
    fixed.style.position = 'fixed'; // restore when you scroll up
  
}


const stopBeforeFooter = () => {
	const fixed = document.querySelector('.stop-before-footer')
	const footer = document.querySelector('#footer');
	if(fixed){
	document.addEventListener("scroll", function(){
	  checkOffset(fixed, footer);
	});
	}
}


export { stopBeforeFooter }
