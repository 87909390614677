const validatePassword = function(e) {
  const password = e.target.value;
  
  
  document.querySelector('.pwd-min-length').classList.toggle('shown', password.length < 8);
  document.querySelector('.pwd-no-number').classList.toggle('shown', !(/\d/.test(password)));
  document.querySelector('.pwd-no-upper-case').classList.toggle('shown', !(/[A-Z]/.test(password)));
  document.querySelector('.pwd-no-lower-case').classList.toggle('shown', !(/[a-z]/.test(password)));
  
  let hasRepetitivePwd = false
  for(var i = 2; i < password.length; i++) {
    if (password[i] == password[i-1] && password[i] == password[i-2]) {
      hasRepetitivePwd = true;
      break
    }
  }
  document.querySelector('.pwd-repetitive').classList.toggle('shown', hasRepetitivePwd);
  
  const hasErrors = document.querySelectorAll('.password-validation-errors li.shown').length > 0;
  document.querySelector('.subito-password-field').classList.toggle('is-invalid', hasErrors);
}

const subitoSignup = () => {
  const form = document.querySelector('.subito-signup-form');
  const passwordField = document.querySelector('.subito-password-field');
  
  if (form && passwordField) {
    passwordField.addEventListener('blur', validatePassword);
  }

}


export { subitoSignup }
 
