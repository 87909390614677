const getScreenWidth = () => {
    return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

const organiseCards = () => {
  const resellerList = document.querySelectorAll('.reseller-cards-index');
  const container = document.querySelector('.reseller-container')
  const cardWidth = 260;
  const paddingLeft = (container.offsetWidth % cardWidth) / 2
  const numberPerRow = Math.floor(container.offsetWidth/cardWidth)
  const cardHeight = 340
  let x = 0
  let top = 0
  let left = paddingLeft
  container.style.height = `${cardHeight}px`
  resellerList.forEach((reseller) => {
    if(!reseller.classList.contains('hidden')) {
      reseller.style.left = `${left}px`
      reseller.style.top = `${top}px`
      x += 1
      left += cardWidth
      if (x % numberPerRow === 0) {

        top += cardHeight
        left = paddingLeft
        container.style.height = `${top + cardHeight}px`
      }
    }
  })

}

const showReseller = (reseller) => {
  reseller.classList.remove('hidden');
  reseller.classList.remove('reseller-is-disappearing');
  reseller.classList.add('reseller-is-appearing');
}


const hideReseller = (reseller) => {
  reseller.classList.add('reseller-is-disappearing')
  reseller.classList.remove('reseller-is-appearing')
  setTimeout(() => {reseller.classList.add('hidden')}, 200)
}

const resellerFilter = (resellerList, region) => {
  resellerList.forEach((reseller) => {
    if(region === 'Tous'){
      showReseller(reseller)
    }
    else if(reseller.dataset.region === region ) {
      showReseller(reseller)
    }
    else{
      hideReseller(reseller)
    }
  })
  setTimeout(() => {organiseCards()}, 200)
}

const initResellerFilter = () => {
  const resellerList = document.querySelectorAll('.reseller-cards-index')
  if ( resellerList.length > 0 ) {
    window.addEventListener('resize', organiseCards)
    organiseCards(resellerList)
    const regionFilters = document.querySelector('.reseller-filter').querySelectorAll('li')
    regionFilters.forEach((regionFilter) => {
      regionFilter.addEventListener('click', () => {
        regionFilters.forEach((rF) => {
          rF.classList.remove('active')
        })
        regionFilter.classList.add('active')
        resellerFilter(resellerList, regionFilter.dataset.region)
      })
    })

    setTimeout(() => {organiseCards()}, 500)
  }

}

export { initResellerFilter }


