const accordion = () => {
  const accordions = document.querySelectorAll('.accordion')
  if (accordions.length > 0){
    accordions.forEach((accordion) => {
      const dts = accordion.querySelectorAll('dt')
      const dds = accordion.querySelectorAll('dd')
      for (let i = 0; i < dts.length ; i++){
        dts[i].addEventListener('click', (event) => {
          event.preventDefault()
          dds[i].classList.toggle('active')
        })
      }
    })
  }
}

export { accordion }
