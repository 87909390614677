const localeCheckboxes = () => {
  const container = document.querySelector(".locale-checkboxes")
  const hiddenInput = document.querySelector("#job_locale")
  if(container && hiddenInput){
    const checkboxes = container.querySelectorAll("input")
    let localeValue = []
    checkboxes.forEach((box) => {
      if (box.checked){
        localeValue.push(box.id)
      }
      box.addEventListener("click", (event) => {
        if(event.target.checked){
          localeValue.push(box.id)
        } else {
          const index = localeValue.indexOf(box.id)
          localeValue.splice(index, 1)
          console.log(localeValue, index)
        }
        hiddenInput.value = localeValue
      })
    })
    hiddenInput.value = localeValue
  }
}

export { localeCheckboxes }
