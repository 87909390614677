const toggleJobdropDown = (careerBox) => {
  careerBox.forEach((box) => {
    const dd = box.querySelector('.career-details-info')
    //box.style.height = box.parentElement.offsetHeight + "px";
    dd.style.display = "block";
    const extendedHeight = box.scrollHeight + "px"
    dd.style.display = "none";
    const buttonShow = box.querySelector('.show-detail')
    const buttonHide = box.querySelector('.cancel-btn')
    box.style.height = box.offsetHeight + "px"
    const normalHeight = box.offsetHeight
    buttonShow.addEventListener('click', (event) => {
      event.preventDefault()
      dd.style.display = "block"
      box.style.height = extendedHeight
      buttonShow.style.display = "none"
    })
    buttonHide.addEventListener('click', (event) => {
      event.preventDefault()
      box.style.height = normalHeight + "px"
      dd.style.display = "none"
      buttonShow.style.display = "block"
    })
  })
}

const initJobDropDown = () => {
  const careerBox = document.querySelectorAll('.career-box')
  if (careerBox.length > 0){
    document.addEventListener('DOMContentLoaded', function() {
      setTimeout(toggleJobdropDown, 1000, careerBox);
    })
  }


}

export { initJobDropDown }
