const toggleFaq = () => {
	const toggler = document.querySelectorAll("#faqToggler")
	if(toggler.length > 0){
		const hiddenFaq = document.querySelectorAll('.hiddenFaq')
		toggler.forEach((toggle) => {
			toggle.addEventListener('click', () => {
				hiddenFaq.forEach((faq) => {
					faq.classList.toggle('hidden')
				})
			})
			toggle.addEventListener('mouseover', () => {
				const allCards = document.querySelectorAll('.card')
				const cards = []
				allCards.forEach((card) => {
					if(!card.classList.contains('hidden')){
						cards.push(card)
					}
				})
				cards.forEach((card) => {
					if(cards[cards.length - 1] === card){
						card.style.boxShadow = "none"
					}
				})
			})
			toggle.addEventListener('mouseleave', () => {
				const cards = document.querySelectorAll('.card')
				cards.forEach((card) => {
					card.style.boxShadow = "0px 2px 8px -2px rgba(0,0,0,0.75)"
				})
			})
		})
	}

	/*if ($("#dropdownMenuLink").length) {
    $(document).on('click', '#dropdownMenuLink', function(e) {
      e.preventDefault();
      console.log('click');
      $("#dropdownMenuLink").dropdown('show');
    });
  }*/

  /*document.addEventListener('click', function(event) {
    if (event.target.matches('#dropdownMenuLink')) {
      console.log('click');
      $("#dropdownMenuLink").dropdown('show');
    }
  }, false);*/
}

export { toggleFaq }
