const stickySecNav = () => {
  const secNavbar = document.querySelector('.secondary-navbar')
  if (secNavbar){
    window.innerHeight = screen.availHeight;
    document.addEventListener('scroll', (event) => {
    //4O is the size of the primary navbar
    if (window.pageYOffset < 40){
        const offset = 40 - window.pageYOffset
        secNavbar.style.top = `${offset}px`
        secNavbar.style.boxShadow = `none`
      } else {
        //secNavbar.style.top = `60px`
        secNavbar.style.top= `0px`
        secNavbar.style.boxShadow = `0px 5px 6px -4px rgba(0,0,0,0.5)`
      }
    })
  }
}

export { stickySecNav }
