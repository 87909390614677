import flatpickr from "flatpickr"

const datePicker = document.querySelector(".datepicker")
if(datePicker){
  const input = document.querySelector("#article_created_at")
  if(input.value === ""){
    flatpickr(".datepicker", {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        defaultDate: Date.now()
    })
  } else {
    flatpickr(".datepicker", {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
    })
  }
}

