const getScreenWidth = () => {
    return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

const organiseCards = () => {
  const partnerList = document.querySelectorAll('.partner');
  const container = document.querySelector('.partners')
  const cardWidth = 570;
  const paddingLeft = 0 //(container.offsetWidth % cardWidth) / 2
  const numberPerRow = 2 //Math.floor(container.offsetWidth/cardWidth)
  const cardHeight = 250
  let x = 0
  let top = 0
  let left = paddingLeft
  //container.style.height = `${cardHeight}px`
  container.style.height = `${(cardHeight * (partnerList.length / 2)) + cardHeight}px`
  
  partnerList.forEach((partner) => {
    if(!partner.classList.contains('hidden')) {
      partner.style.left = `${left}px`
      partner.style.top = `${top}px`
      x += 1
      left += cardWidth
      if (x % numberPerRow === 0) {

        top += cardHeight
        left = paddingLeft
        //container.style.height = `${top + cardHeight}px`
      }
    }
    partner.querySelector('.partner-desc').classList.remove('more');
  })

}

const showPartner = (partner) => {
  partner.classList.remove('hidden');
  partner.classList.remove('partner-is-disappearing');
  partner.classList.add('partner-is-appearing');
}


const hidePartner = (partner) => {
  partner.classList.add('partner-is-disappearing')
  partner.classList.remove('partner-is-appearing')
  setTimeout(() => {partner.classList.add('hidden')}, 200)
}

const partnerFilter = (partnerList, cat) => {
  partnerList.forEach((partner) => {
    var catList = partner.dataset.category.split(",");
    
    if(cat === 'Tous'){
      showPartner(partner)
    }
    else if(catList.indexOf(cat) != -1 ) {
      showPartner(partner)
    }
    else{
      hidePartner(partner)
    }
  })
  setTimeout(() => {organiseCards()}, 200)
}

const initPartnerFilter = () => {
  const partnerList = document.querySelectorAll('.partner')
  if ( partnerList.length > 0 ) {
    window.addEventListener('resize', organiseCards)
    organiseCards(partnerList)
    const catFilters = document.querySelector('.partner-filter').querySelectorAll('li')
    catFilters.forEach((catFilter) => {
      catFilter.addEventListener('click', () => {
        catFilters.forEach((rF) => {
          rF.classList.remove('active')
        })
        catFilter.classList.add('active')
        partnerFilter(partnerList, catFilter.dataset.category)
      })
    })
    
    // Set up 'see more' toggle
    document.querySelectorAll('.partner-desc').forEach((parent) => {
      const link = parent.querySelector('.toggle-more');
      if (link) {
        link.addEventListener('click', (evt) => {
          evt.preventDefault();
          
          var $parent = $(evt.target).parents(".partner");
          var $modal = $('.partners-modal')
          
          $modal.find(".name").text($parent.find('.partner-name').data('name'));
          $modal.find(".desc").text($parent.find('.partner-desc').data('desc'));
          $modal.find(".image").attr('src', $parent.find('.image').data('url'));
          
          $modal.modal('show');
        });
      }
    });
    
  }
}

export { initPartnerFilter }


