import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

const updateInput = (marker) => {
  const latInput = document.querySelector('#reseller_latitude')
  const longInput = document.querySelector('#reseller_longitude')
  latInput.value = marker.lat
  longInput.value = marker.lng
}

const fitMapToMarkers = (map, markers) => {
  const bounds = new mapboxgl.LngLatBounds();
  markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
  map.fitBounds(bounds, { padding: 70, maxZoom: 15 });
};
const initMapbox = () => {
  const mapElement = document.getElementById('map');
  const mapIndex = document.getElementById('mapIndex')
  if (mapElement) { // only build a map if there's a div#map to inject into
    let marker
    const aToken = mapElement.dataset.mapboxApiKey;
    mapboxgl.accessToken = aToken
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom: 9,
      center: [4.399, 50.714]
    });
    map.on("click", (evt) => {
      if (marker != undefined) { marker.remove()}
      marker = new mapboxgl.Marker()
        .setLngLat(evt.lngLat)
        .addTo(map)
      updateInput(evt.lngLat)
    })

    map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken }));
    const addressInput = document.querySelector('#reseller_address')
    addressInput.addEventListener('focusout', async () => {
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${addressInput.value}.json?access_token=${aToken}`)
      .then(res => res.json()).then(res => {
          if (marker != undefined) { marker.remove()}
          const posObject = {lat: res.features[0].geometry.coordinates[1], lng: res.features[0].geometry.coordinates[0]}
          marker = new mapboxgl.Marker().setLngLat(posObject).addTo(map)
          map.flyTo({center: posObject})
          updateInput(posObject)
        })
    })
  }
  if (mapIndex) {
    mapboxgl.accessToken = mapIndex.dataset.mapboxApiKey;
    const map = new mapboxgl.Map({
      container: 'mapIndex',
      style: 'mapbox://styles/mapbox/streets-v10'
    });
    const markers = JSON.parse(mapIndex.dataset.markers);
    markers.forEach((marker) => {
      const popup = new mapboxgl.Popup({ offset: 25 })
        .setHTML(marker.infoWindow)
      new mapboxgl.Marker()
        .setLngLat([ marker.lng, marker.lat ])
        .setPopup(popup)
        .addTo(map)
    });
    fitMapToMarkers(map, markers);
    map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken }));
  }
}

export { initMapbox }
